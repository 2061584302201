document.addEventListener('turbolinks:load', function(e) {
  $('.help-page-children').hide();

  $('.help-page').on('click', function(e) {
    if (e.target === this) {
      $(this).children('.help-page-children').toggle();
      $(this).addClass('active-page').siblings().removeClass('active-page');
      $('.help-page-child').removeClass('active-child');
    }
  });

  $('.help-page-child').on("click", function() {
    $(this).addClass('active-child').siblings().removeClass('active-child');
  });

  $('body').on("click", function(e) {
    var element = $(e.target).closest('.help-pages > .help-page');
    $('.help-pages > .help-page').not(element).find('.help-page-children').hide();
  });
});


