const PortfolioSlider = window.PortfolioSlider = {
  settings() {
    return {
      dots: false,
      infinite: false,
      centerMode: true,
      nextArrow: '<i class="icon-arrow-c-right"></i>',
      prevArrow: '<i class="icon-arrow-c-left"></i>'
    }
  },
  init(element) {
    $(element).each(function() {
      const $this = $(this);
      const options = $(this).data('options') || {};
      $this.slick(PortfolioSlider.settings(options));
    });
  }
}

document.addEventListener('turbolinks:load', function() {
  PortfolioSlider.init('.js-portfolio__slider');
});

$(document).on('turbolinks:before-cache', function() {
  $('.js-portfolio__slider').slick('unslick');
});