import Cookies from 'js-cookie';

class ArchibrixCookies {
    constructor() {
        this.setupEvents();
    }

    setupEvents() {
        const self = this;

        const $acceptButton = $('.js-cookies-accept-all');
        if ($acceptButton.length) {
            $acceptButton.on('click', function(e) {
                e.preventDefault();
                self.setCookie(true);
            });
        }

        const $settingsButton = $('.js-cookies-settings');
        if ($settingsButton.length) {
            $settingsButton.on('click', function(e) {
                e.preventDefault();
                self.settingsCookie();
            });
        }

        this.$closeModalButton = $('.js-cookie-modal-close');
        if (this.$closeModalButton.length) {
            this.$closeModalButton.on('click', function(e) {
                e.preventDefault();
                self.closeModal();
            });
        }

        const $confirmButton = $('.js-cookies-confirm-choices');
        if ($confirmButton.length) {
            $confirmButton.on('click', function(e) {
                e.preventDefault();
                self.setCookie();
            });
        }
    }

    setCookie(acceptAll = false) {
        const path = '/';
        const expires = 365;
        const isAnalyticsEnabled = acceptAll ? true : $('.js-cookies-analytics').is(':checked');
        const isMarketingEnabled = acceptAll ? true : $('.js-cookies-marketing').is(':checked');

        Cookies.set('_archibrix_cookie_consented', true, { path: path, expires: expires, domain: '.archibrix.com' });
        Cookies.set('_archibrix_cookie_analytics', isAnalyticsEnabled, { path: path, expires: expires, domain: '.archibrix.com' });
        Cookies.set('_archibrix_cookie_marketing', isMarketingEnabled, { path: path, expires: expires, domain: '.archibrix.com' });

        $('body').removeClass('modal-open');
        $('.js-cookies-container').removeClass('open');

        if (isAnalyticsEnabled || isMarketingEnabled) {
            Turbolinks.visit(window.location.href);
        }
        this.readCookies();
    }

    readCookies() {
        this.isConsentEnabled = Cookies.get('_archibrix_cookie_consented') === 'true';
        this.isAnalyticsEnabled = Cookies.get('_archibrix_cookie_analytics') === 'true';
        this.isMarketingEnabled = Cookies.get('_archibrix_cookie_marketing') === 'true';
    }

    settingsCookie() {
        $('.js-cookies-container').find('.cookie-modal').addClass('open');
        $('.js-cookies-container').find('.cookies-bottom-modal').removeClass('open');
    }

    closeModal() {
        this.$closeModalButton.closest('.cookie-modal').removeClass('open');
        $('.js-cookies-container').find('.cookies-bottom-modal').addClass('open');
    }
}

document.addEventListener('turbolinks:load', function(event) {
    new ArchibrixCookies();
});