const tus = require('tus-js-client');

document.addEventListener('turbolinks:load', function() {

  // show file name in input field
  $('.js-source-file').on('change', function(e) {
    const file = e.target.files[0];
    if (!file) { return; }

    const $this = $(this);
    const $progressBar = $this.closest('.js-upload-file').find('.js-progress-bar');
    const $cancelButton = $('.js-cancel-btn');
    const $formActions = $('.js-form-actions');

    // set label
    const fileName = file.name.split('\\').pop();
    $this.siblings('.custom-file-label').addClass('disabled').html(fileName);

    // show cancel
    $cancelButton.removeClass('hide');

    // disable
    $formActions.hide();
    $this.addClass('disabled');
    $this.val('');

    const upload = new tus.Upload(file, {
      endpoint: '/files',
      metadata: { filename: file.name, content_type: file.type },
      chunkSize: 1 * 1024 * 1024, // 1 MB

      onError(error) {
        console.error(error);
      },

      onProgress(bytesUploaded, bytesTotal) {
        var progress = parseInt(bytesUploaded / bytesTotal * 100, 10);
        var percentage = `${progress}%`;
        $progressBar.find('.progress').css('width', percentage);
        $progressBar.find('.progress-number').html(percentage);
      },

      onSuccess() {
        const uploadFile = {
          url: upload.url,
          file: {
            type: upload.file.type,
            size: upload.file.size,
            name: upload.file.name,
            lastModified: upload.file.lastModified,
          }
        };

        $formActions.show();
        $('.js-continue-btn').removeAttr('disabled');
        $cancelButton.addClass('hide');

        $('.js-source-document').val(JSON.stringify(uploadFile));
      }
    });

    $cancelButton.on('click', function(e) {
      e.preventDefault();
      upload.abort();
      $(this).addClass('hide');
      $formActions.show();
      $this.removeClass('disabled');
    });

    upload.findPreviousUploads().then((previousUploads) => {
      const text = $this.data('alreadyUploaded');
      const latestUpload = askToResumeUpload(previousUploads, text);

      if (latestUpload) {
        upload.resumeFromPreviousUpload(latestUpload);
      }

      upload.start();
    });

    function askToResumeUpload(previousUploads, text) {
      if (previousUploads.length === 0) return null;

      const answer = confirm(text);

      if (answer) {
        return previousUploads[0];
      }
    }
  });

  $(document.body).on('change', '.js-file-upload', function(e) {
    const file = e.target.files[0];
    if (!file) { return; }

    // set label
    const fileName = file.name.split('\\').pop();
    $(this).closest('.custom-file').find('.custom-file-label').html(fileName);
  });

  // load and set label
  $('.js-file-upload').each(function() {
    const $this = $(this);
    const fileName = $this.data('fileName');
    $this.closest('.custom-file').find('.custom-file-label').html(fileName);
  });

  // replace template
  $('.blueprint-documents').each(function() {
    const $this = $(this);
    const $addFields = $this.find('.add_fields');
    let template = $addFields.attr('data-association-insertion-template');
    if (template) {
      template = template.replace(/DOCUMENT_REPLACE/g, $this.data('addLabel'));
      $addFields.attr('data-association-insertion-template', template);
    }
  });

});