document.addEventListener('turbolinks:load', function(){
  $(".presentations__slider").slick({
    dots: true,
    arrows: false,
    infinite: true,
    fade: true,
    cssEase: 'linear',
    autoplay: true,
    speed: 300,
    autoplaySpeed: 5000,
    appendDots: $(".slider__nav"),
    customPaging: function(slider, i) {
      var $slide = slider.$slides.eq(i);
      var title = $slide.find('.presentation__title').text();
      var info = $slide.find('.presentation__info').text();
      return $('<div class="slider__item"><h5 class="presentation__title">' + title + '</h5>' + '<div class="presentation__info">' + info + '</div>' + '<div class="progress-bar-container"><div class="progress-bar"></div></div></div>');
    }
  });

  if( $(window).width() < 1200 ){
    $(".presentations__slider").slick('unslick');
  }
});