import Dropzone from 'dropzone';
Dropzone.autoDiscover = false;

const DZ = window.DZ = {
  init() {
    this.setupEvents();
  },

  setupEvents() {
    $(".js-dz").each(function(index, elm) {
      var $this = $(this);
      var maxFiles = $this.data('maxFiles') || 256;
      var multiple = Boolean($this.data('multiple')) || true;
      var thumbnailMethod = $this.data('thumbnailMethod') || 'crop';
      var dz = new Dropzone(elm, {
        url: $this.data('url'),
        addRemoveLinks: true,
        multiple,
        maxFiles,
        dictRemoveFile: '',
        thumbnailWidth: 170,
        thumbnailHeight: 170,
        thumbnailMethod,
        acceptedFiles: '.pdf,.jpg,.jpeg,.png',
        success: function(file) {
          var previewElement = $(file.previewElement);
          var ext = file.name.split('.').pop();
          previewElement.data('id', file.id);
          previewElement.data('destroy', file.url);
          if (ext == "pdf") {
            previewElement.find(".dz-image").addClass('file');
          }
        },
        init: function() {
          var documents = $this.data('documents');
          let myDz = this;
          $.each(documents, function(key, value) {
            let mockFile = { id: value.id, name: value.filename, size: value.size, url: value.destroy, baseURI: value.destroy, accepted: true }
            myDz.displayExistingFile(mockFile, value.url, null, 'anonymous');
            myDz.files.push(mockFile);
          });
        },
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        previewTemplate: document.querySelector('#dz-preview-template').innerHTML
      });
      dz.on("success", function(file, response) {
        var previewElement = $(file.previewElement);
        previewElement.data('id', response.id);
        previewElement.data('destroy', response.url);
      })
      dz.on('addedfile', function(file) {
        var ext = file.name.split('.').pop();
        if (ext == "pdf") {
            $(file.previewElement).find(".dz-image").addClass('file');
        } else {
            $(file.previewElement).find(".dz-image").removeClass('file');
        }
      });
      dz.on("removedfile", function(file) {
        var previewElement = $(file.previewElement);
        var removeUrl = $this.data('removeUrl') || previewElement.data('destroy')
        $.ajax({
          type: 'DELETE',
          url: removeUrl,
          headers: {
            'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
          },
        });
      });
      dz.on("error", function(file, response) {
        $(file.previewElement).find('.dz-error-message').text(response.message);
      });
      dz.on('maxfilesexceeded', function (files) {
        this.removeAllFiles();
        this.addFile(files);
      });
    });
  }
}

document.addEventListener('turbolinks:load', function() {
  DZ.init();
});