const PortfolioLandingSlider = window.PortfolioLandingSlider = {
  settings() {
    return {
      infinite: false,
      dots: true,
      fade: true,
      speed: 0.1,
      nextArrow: '<i class="icon-arrow-c-right"></i>',
      prevArrow: '<i class="icon-arrow-c-left"></i>'
    }
  },
  init(element) {
    $(element).each(function() {
      const $this = $(this);
      const options = $this.data('options') || {};
      $this.slick(PortfolioLandingSlider.settings(options));
    });
  }
}

document.addEventListener('turbolinks:load', function() {
  PortfolioLandingSlider.init('.js-portfolio-landing__slider');
});

$(document).on('turbolinks:before-cache', function() {
  $('.js-portfolio-landing__slider').slick('unslick');
});