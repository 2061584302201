import Rails from '@rails/ujs';
import toastr from 'toastr';

window.$ = window.jQuery = $;
window.Rails = Rails;
window.toastr = toastr;

document.addEventListener('turbolinks:load', function() {
  var typeformLink = $('.js-typeform');
  if(typeformLink.length) {
    var typeformURL = typeformLink.attr('href');
    var typeform = window.typeformEmbed.makePopup(typeformURL, {
      hideHeaders: true,
      hideFooter: true
    });
  }

  $('.js-typeform').on('click', function (e) {
    e.preventDefault();
    typeform.open();
  });

  $('.js-submit-select').on('change', function() {
    const $form = $(this).closest('form');
    $form[0].submit();
  });

  $('[data-toggle="tooltip"]').tooltip({ html: true });
  $('[data-toggle="tooltip"]').click(function(e){
    e.preventDefault();
  });

  $('.js-sticky-sidebar').each(function() {
    const topSpacing = $(this).data('top-spacing');
    new StickySidebar(this, {
      topSpacing: topSpacing,
      containerSelector: '.sticky-main',
    });
  });

  $('[data-tr-url]').click(function(e) {
    const url = $(this).data('tr-url');
    if (e.metaKey || e.ctrlKey) {
      window.open(url, '_blank');
    } else {
      window.open(url, '_self');
    }
  });

  $('.js-show-personal-info').on('click', function(e) {
    e.preventDefault();
    $(this).closest('.js-personal').find('.js-personal-info').toggleClass('hide');
  });

  $('.js-copy-text').on('click', function(e) {
    e.preventDefault();
    var target = document.getElementById($(this).data('target'));

    target.select();
    target.setSelectionRange(0, 9999);

    document.execCommand('copy');
  });

  $('.js-auto-select').on('click focus', function(e) {
    e.preventDefault();
    $(this).select();
  });

  $('#user_subdomain').on('keyup', function () {
    var input = $(this).val();
    var regex = input.replace(/[^a-zA-Z0-9\-]/, "");
    $(this).val(regex);
  });

  window.scrollTo(0, 0);
});

document.addEventListener('turbolinks:before-cache', function () {
  $('.js-select').data('select2') && $('.js-select').select2('destroy');
});