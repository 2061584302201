window.SocialShare = {
  openUrl(url, width, height) {
    if (width == null) { width = 640; }
    if (height == null) { height = 480; }
    const left = (screen.width / 2) - (width / 2);
    const top = (screen.height * 0.3) - (height / 2);
    const opt = `width=${width},height=${height},left=${left},top=${top},menubar=no,status=no,location=no`;
    window.open(url, 'popup', opt);
    return false;
  },

  share(el) {
    if (el.getAttribute === null) {
      el = document.querySelector(el);
    }

    const site = el.getAttribute("data-site");
    const $parent = el.parentNode;
    let title = encodeURIComponent(el.getAttribute("data-" + site + "-title") || $parent.getAttribute('data-title') || '');
    const img = encodeURIComponent($parent.getAttribute("data-img") || '');
    let url = encodeURIComponent($parent.getAttribute("data-url") || '');
    const via = encodeURIComponent($parent.getAttribute("data-via") || '');
    const desc = encodeURIComponent($parent.getAttribute("data-desc") || ' ');

    // tracking click events if google analytics enabled
    const ga = window[window['GoogleAnalyticsObject'] || 'ga'];
    if (typeof ga === 'function') {
      ga('send', 'event', 'Social Share Button', 'click', site);
    }

    if (url.length === 0) {
      url = encodeURIComponent(location.href);
    }
    switch (site) {
      case "email":
        location.href = `mailto:?to=&subject=${title}&body=${url}`;
        break;
      case "twitter":
        var hashtags = encodeURIComponent(el.getAttribute("data-" + site + "-hashtags") || $parent.getAttribute("data-hashtags") || '');
        var via_str = '';
        if (via.length > 0) { via_str = `&via=${via}`; }
        SocialShare.openUrl(`https://twitter.com/intent/tweet?url=${url}&text=${title}&hashtags=${hashtags}${via_str}`, 650, 300);
        break;
      case "facebook":
        SocialShare.openUrl(`http://www.facebook.com/sharer/sharer.php?u=${url}`, 555, 400);
        break;
      case "pinterest":
        SocialShare.openUrl(`http://www.pinterest.com/pin/create/button/?url=${url}&media=${img}&description=${title}`);
        break;
      case "linkedin":
        SocialShare.openUrl(`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}&summary=${desc}`);
        break;
      case "whatsapp_app":
        var whatsapp_app_url = `whatsapp://send?text=${title}%0A${url}`;
        window.open(whatsapp_app_url, '_top');
        break;
      case "whatsapp":
        SocialShare.openUrl(`https://web.whatsapp.com/send?text=${title}%0A${url}`);
        break;
    }
    return false;
  }
};