document.addEventListener('turbolinks:load', function() {

  // show file name in input field
  $(document.body).on('click', '.js-upload-image-btn', function(e) {
    e.preventDefault();

    const $this = $(this);
    const $container = $(this).closest('.upload-image');
    const $inputs = $container.find('.upload-inputs');
    const $uploadButton = $container.find('.js-upload-image-btn');
    const $images = $container.find('.upload-images');
    const isMultiple = $uploadButton.data('multiple');

    let $fileInput;
    if (isMultiple) {
      const imageLength = $images.find('.upload-image-preview').length;
      $inputs.append($uploadButton.data('input').replace(/NEW_RECORD/g, imageLength));
      $fileInput = $inputs.find('.js-file-input:last');
    } else {
      $fileInput = $inputs.find('.js-file-input');
      if (!$fileInput.length) {
        $inputs.append($uploadButton.data('input'));
        $fileInput = $inputs.find('.js-file-input');
      }
      $fileInput.off('change');
    }

    if ($fileInput.length) {
      $fileInput[0].accept = "image/*"
    }

    $fileInput.trigger('click');

    $fileInput.on('change', function(e) {
      const file = e.target.files[0];
      const html = `<a href="#" class="upload-image-preview js-upload-image-preview" style="background-image:url('${URL.createObjectURL(file)}');" ></a>`;
      if (isMultiple) {
        $images.append(html);
      } else {
        $images.prepend(html);
        $this.hide();
      }
    });
  });

  $(document.body).on('click', '.js-upload-image-preview', function(e) {
    e.preventDefault();
    const $this = $(this);
    const $container = $(this).closest('.upload-image');
    const $uploadButton = $container.find('.js-upload-image-btn');
    const isMultiple = $uploadButton.data('multiple');
    $this.hide();
    if (isMultiple) {
      $this.next().val(1).prop('checked', true);
    } else {
      $this.next().val(0).prop('checked', false);
      const $fileInput = $container.find('.js-file-input');
      $fileInput.val('');
      $uploadButton.show();
    }
  });

});