const DP = window.DP = {
  init() {
    var maxDate = new Date();
    maxDate.setFullYear(maxDate.getFullYear() - 18);
    $('.datepicker').each(function() {
      new Cleave(this, {
        date: true,
        delimiter: '/',
        datePattern: ['d', 'm', 'Y']
      });
      $(this).datepicker({
        language: 'de',
        startView: 2,
        format: 'dd/mm/yyyy',
        endDate: maxDate
      });
    });
  }
}

document.addEventListener('turbolinks:load', function() {
  DP.init();
});