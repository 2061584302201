const Select = window.Select = {
  options: {
    minimumResultsForSearch: 30
  },

  init(options = {}) {
    $('.js-select').select2({ ...Select.options, ...options });
  },

  initParentChild(options = {}) {
    $(document.body).on('select2:select', '.js-select-parent', function(e) {
      e.preventDefault();
      const $this = $(this);
      const id = e.params.data.id;
      const url = $this.data('url');

      if (id && url) {
        $.get(`${url}?parent_id=${id}`, function(data) {
          $this.closest('.select-parent-child').find('.js-select-child').select2('destroy').empty().select2({
            ...Select.options,
            ...options,
            data,
            templateSelection: function(item) {
              $(item.element).data('onlyMultiple', item.only_multiple);
              return item.text;
            }
          });
        });
      }
    });
  },

  initBuildingType() {
    $(document.body).on('select2:select', '.js-building-type-select', function(e) {
      e.preventDefault();
      const id = e.params.data.id;
      const $mutipleUnitSelect = $('.js-multiple-unit-select');

      if (id) {
        const $element = $(e.params.data.element);
        const onlyMultiple = $element.data('onlyMultiple');
        if (onlyMultiple) {
          $mutipleUnitSelect.val('true').trigger('change');
          $mutipleUnitSelect.closest('.form-group').hide();
        } else {
          $mutipleUnitSelect.val('').trigger('change');
          $mutipleUnitSelect.closest('.form-group').show();
        }
      } else {
        $mutipleUnitSelect.closest('.form-group').hide();
      }
    });
  },

  initAutocomplete(removeExisting = true) {
    const self = this;
    $('.js-select-tags').each(function() {
      const $this = $(this);
      $this.select2({
        minimumInputLength: 1,
        closeOnSelect: false,
        maximumSelectionLength: 10,
        ajax: {
          url: $this.data('url'),
          dataType: 'json',
          data: function (params) {
            return { query: params.term };
          },
          processResults: function (results) {
            if (removeExisting) {
              results = results.filter(item => {
                return !$this.val().includes(`${item.id}`);
              });
            }
            return { results };
          }
        }
      });
      self.selectedOptions($this);
    });
  },

  selectedOptions($element) {
    const values = $element.data('selected');
    if (values) {
      values.forEach(function(tag) {
        const option = new Option(tag.text, tag.id, true, true);
        $element.append(option);
      })
      $element.trigger('change');
      return $element;
    }

    $('.js-select-tags').on('select2:select', function(){
      $('.select2-search__field').val("");
    });
  }
}

document.addEventListener('turbolinks:load', function() {
  Select.init();
  Select.initParentChild();
  Select.initBuildingType();
});