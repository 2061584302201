// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require("jquery")
require("bootstrap")
require("select2/dist/js/select2")
require("slick-carousel/slick/slick")
require("sticky-sidebar")
require("@nathanvda/cocoon")
require("tus-js-client/dist/tus")
require("@chenfengyuan/datepicker/src/js")
require("cleave.js/dist/cleave")

require("app")
require("select")
require("help-pages-expand")
require("modal")
require("load-more")
require("loader")
require("menu")
require("gallery")
require("architects-search")
require("presentation-slider")
require("testimonial-slider")
require("checkout")
require("upload")
require("radio")
require("blueprint")
require("upload-image")
require("social-share")
require("cookies")
require("dz")
require("autonumeric")
require("dp")
require("portfolio")
require("portfolio-slider")
require("portfolio-landing-slider")

import AutoNumeric from 'autonumeric'
import fileUpload from 'fileUpload'

// listen on 'turbolinks:load' instead of 'DOMContentLoaded' if using Turbolinks
document.addEventListener('turbolinks:load', () => {
  document.querySelectorAll('.file-upload').forEach(fileInput => {
    fileUpload(fileInput)
  })
  if($('.js-currency').length) {
    const numeric = new AutoNumeric.multiple('.js-currency', { digitGroupSeparator: '.', decimalCharacter: ',' });
  }
})
