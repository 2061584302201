document.addEventListener('turbolinks:load', function() {
  // set company name
  function setCompanyName(data = false) {
    const $companyName = $('.js-invoice-company-name');
    if (data) {
      $companyName.val(data.name).prop('readonly', true);
    } else {
      $companyName.val('').prop('readonly', false);
    }
  }

  // set total price name
  function setTotalPrice(data = false) {
    const $totalPrice = $('.js-price-total');
    if ($totalPrice.length  && data) {
      const totalPrice = data.name ? $totalPrice.data('total-price') : $totalPrice.data('total-price-with-tax');
      $totalPrice.text(totalPrice);
    }
  }

  // set country
  function setCountry(data = false) {
    const $countrySelect = $('.js-select-country');
    if (data) {
      const countryCodeVal = $countrySelect.find('[data-code='+ data.country_code +']').val();
      $countrySelect.select2('val', countryCodeVal);
      // TODO: add readonly not disabled
    } else {
      // TODO: remove readonly
    }
  }

  // set data
  function setData(data = false) {
    if (data) {
      setCompanyName(data)
      setTotalPrice(data);
      setCountry(data);
    } else {
      setCompanyName();
      setTotalPrice();
      setCountry();
    }
  }

  const $checkoutForm = $('.js-checkout-form');
  if ($checkoutForm.length) {

    // check vat
    $('.js-invoice-vat').on('blur', function() {
      const $self = $(this);
      const vatNumber = $self.val();
      if (vatNumber) {
        $('.input-loader .spin').show();
        const vatUrl = $self.data('vatUrl');
        $.get(vatUrl + '?vat_id=' + vatNumber, function(data) {
          if (data) {
            setData(data);
          } else {
            setData();
          }
          $('.input-loader .spin').hide();
        });
      } else {
        setData();
      }
    });

    // change country
    $('.js-select-country').on('select2:select', function(e) {
      const vatCalculatedPerCountry = $checkoutForm.data('vatCalculatedPerCountry');
      const invoiceVat = $('.js-invoice-vat').val();
      if (!invoiceVat && vatCalculatedPerCountry) {
        const countryId = e.params.data.id;
        const changeCountryUrl = $checkoutForm.data('changeCountryUrl');

        Rails.ajax({ url: `${changeCountryUrl}/${countryId}`, type: 'PUT' });
      }

    });
  }

});