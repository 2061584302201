const Radio = {
  init() {
    this.setupEvents();
  },

  setupEvents() {
    $('.js-radio-input').on('change', function() {
      $('.js-radio-input').closest('.custom-radio').removeClass('checked');
      $(this).closest('.custom-radio').addClass('checked');
    });
  }
}

document.addEventListener('turbolinks:load', function() {
  Radio.init();
});
