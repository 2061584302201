document.addEventListener('turbolinks:load', function() {
  const $sidebarDropdownTrigger = $('.portfolio__sidebar .dropdown-trigger');
  const $plus = $('.portfolio__sidebar .dropdown-trigger .icon-plus');
  const $minus = $('.portfolio__sidebar .dropdown-trigger .icon-minus');
  const $mobileNav = $('.portfolio__mobile-nav');
  const $openMobileMenu = $('.portfolio__mobile-nav .icon-menu');
  const $mobileMenu = $('.portfolio__mobile-menu');
  const $closeMobileMenu = $('.portfolio__mobile-menu .icon-close-small');
  const $previewNav = $('.portfolio__preview');
  const $portfolioNav = $('.portfolio__navbar');
  const $portfolioSidebar = $('.portfolio__sidebar');
  const $layout = $('.portfolio .layout');

  if ($previewNav.length > 0) {
    $portfolioNav.addClass('move-down');
    $portfolioSidebar.addClass('move-down');
    $layout.addClass('move-down');
    $mobileNav.addClass('move-down');
  } else {
    $portfolioNav.removeClass('move-down');
    $portfolioSidebar.removeClass('move-down');
    $layout.removeClass('move-down');
    $mobileNav.removeClass('move-down');
  }

  if ($(window).width() < 768) {
    $portfolioNav.removeClass('move-down');
    $portfolioSidebar.removeClass('move-down');
    $layout.removeClass('move-down');
    $mobileNav.removeClass('move-down');
  }

  $sidebarDropdownTrigger.on('click', function() {
    $minus.toggleClass('hide');
    $plus.toggleClass('hide');
  });

  $openMobileMenu.on('click', function(){
    $mobileMenu.removeClass('hide');
    $('body').css({'overflow-y': 'hidden'});
  });

  $closeMobileMenu.on('click', function(){
    $mobileMenu.addClass('hide');
    $('body').css({'overflow-y': 'scroll'});
  });

  // portfolio settings --> logo upload
  if ($('#user_upload_logo_true').is(':checked')) {
    $('.js-logo-upload').show();
  } else {
    $('.js-logo-upload').hide();
  }

  $('#user_upload_logo_true').on('change', function() {
    $('.js-logo-upload').show();
  })

  $('#user_upload_logo_false').on('change', function() {
    $('.js-logo-upload').hide();
  })

  // portfolio settings --> profile/custom text
  if ($('#user_use_custom_text_true').is(':checked')) {
    $('.js-profile-text__input').hide();
    $('.js-custom-text__input').show();
  } else {
    $('.js-profile-text__input').show();
    $('.js-custom-text__input').hide();
  }

  $('#user_use_custom_text_true').on('change', function() {
    $('.js-profile-text__input').hide();
    $('.js-custom-text__input').show();
  })

  $('#user_use_custom_text_false').on('change', function() {
    $('.js-profile-text__input').show();
    $('.js-custom-text__input').hide();
  })

  // necessary because :empty css selector takes empty space and white space as content
  if ($('.right-nav .social').children().length == 0) {
    $('.right-nav .social').addClass('hidden');
  } else {
    $('.right-nav .social').removeClass('hidden');
  }

  if ($('.personal-info__section .profile-header').css('background-image') === 'none') {
    $('.personal-info__section').css({'margin-top': '110px'});
  }
});