document.addEventListener('turbolinks:load', function() {
  const $blueprintForm = $('.js-blueprint-form');

  if ($blueprintForm.length) {

    const $submitButton = $blueprintForm.find('.js-continue-btn');

    function toggleAutomaticSetup(show) {
      if (show) {
        $('.js-automatic-setup').slideDown();
        $submitButton.attr('disabled', 'disabled');
      } else {
        $('.js-automatic-setup').slideUp();
        $submitButton.removeAttr('disabled');
      }
    }

    // step 3
    $('.js-automatic-setup-radio').on('change', function() {
      if(this.checked) {
        toggleAutomaticSetup(true);
      }
    });

    $('.js-manual-setup-radio').on('change', function() {
      if (this.checked) {
        toggleAutomaticSetup(false);
      }
    });
  }

});