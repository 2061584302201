const Menu = {
  init() {
    this.$original = $('.js-navbar.original');
    this.$cloned = $('.js-navbar.cloned');
    this.$mobileMenu = $('.js-mobile-menu');
    this.setupEvents();
  },
  setupEvents() {
    const self = this;
    window.onscroll = function() { self.onScroll() };
    this.$mobileMenu.on('shown.bs.collapse', function () {
      $('body').addClass('modal-open');
    })
    this.$mobileMenu.on('hidden.bs.collapse', function () {
      $('body').removeClass('modal-open');
    });
  },
  onScroll() {
    if (window.pageYOffset > this.$original.innerHeight()) {
      this.$cloned.addClass('show');
    } else {
      this.$cloned.removeClass('show');
    }
    this.lastScrollTop = window.pageYOffset;
  },
}

document.addEventListener('turbolinks:load', function() {
  Menu.init();
});