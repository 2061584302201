const Modal = window.Modal = {
  init(element = '#ajax-modal') {
    this.element = element;
    this.$ajaxModal = $(element);
    this.setupEvent();
    return this;
  },

  show(html) {
    this.$ajaxModal.html(html);
    $('.modal-backdrop').remove();
    this.$ajaxModal.find('.modal').modal('show');
    return this;
  },

  setupEvent() {
    $(document.body).on('hidden.bs.modal', `${this.element} .modal`, () => {
      this.$ajaxModal.empty();
    });
  },

  close() {
    this.$ajaxModal.find('.modal').modal('hide');
    $('.modal-backdrop').remove();
    return this;
  },

  replace(html, modalBody = '.modal-body', replaceBackdrop = false) {
    const $modalBody = this.$ajaxModal.find(modalBody);
    if (replaceBackdrop) {
      $('.modal-backdrop').remove();
    }
    $modalBody.html(html);
    return this;
  }
};