const Uppy = require('@uppy/core')

import {
  Core,
  FileInput,
  Informer,
  ProgressBar,
  Tus,
  StatusBar,
  AwsS3Multipart,
} from 'uppy'

function fileUpload(input) {
  const fileInput = input.querySelector('.upload-file'),
        hiddenInput = input.querySelector('.upload-data'),
        uploadPreview = input.querySelector('.file-progress'),
        trigger = $(input).find('.js-file-trigger'),
        fileName = input.querySelector('.file-basename'),
        addButton = input.querySelector('.file-add'),
        replaceButton = input.querySelector('.file-replace'),
        removeButton = input.querySelector('.file-remove'),
        fileControl = input.querySelector('.file-control'),
        removeIndicator = $(input).find('.remove-indicator')

  const uppy = new Uppy({
      id: fileInput.id,
      autoProceed: true,
      inline: true,
      allowMultipleUploads: true,
      inputName: 'file',
    })
    // .use(ProgressBar, {
    //   target: uploadPreview
    // })
    .use(StatusBar, {
      target: fileControl,
    })
    uppy.use(AwsS3Multipart, {
      companionUrl: '/', // will call `/s3/multipart/*` endpoints on your app
    })
    // .use(Tus, {
    //   endpoint: '/files',     // path to our tus server
    //   chunkSize: 10*1024*1024, // required unless tus-ruby-server is running on Falcon
    // })

  fileInput.addEventListener('change', (event) => {
    const files = Array.from(event.target.files)

    files.forEach((file) => {
      try {
        uppy.addFile({
          source: 'file input',
          name: file.name,
          type: file.type,
          data: file
        })
      } catch (err) {
        if(err.isRestriction) {
          console.log('Restriction error:', err)
        } else {
          console.error(err)
        }
      }
    })
  })

  uppy.on('file-removed', (file) => {
    fileInput.value = null
  })

  uppy.on('complete', () => {
    $(removeIndicator).prop('checked', false)
    fileInput.value = null
  })

  uppy.on('upload-success', (file, response) => {
    fileInput.value = null
    var uploadedFileData = {
      id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1],
      storage: "cache",
      metadata: {
        filename: file.name,
        size: file.size,
        mime_type: file.type,
      }
    }

    hiddenInput.value = JSON.stringify(uploadedFileData)
    fileName.innerText = file.name
    $(fileName).addClass('filled')
    $(addButton).hide()
    $(replaceButton).show()
    $(removeButton).show()
  })

  uppy.on('cancel-all', () => {
    fileName.innerText = $(fileName).data('placeholder')
    $(fileName).removeClass('filled')
  })

  trigger.on('click', (event) => {
    event.preventDefault()
    uppy.cancelAll()
    $(fileInput).trigger('click')
  });

  $(replaceButton).on('click', (event) => {
    event.preventDefault()
    uppy.cancelAll()
    $(fileInput).trigger('click')
  })

  $(removeButton).on('click', (event) => {
    event.preventDefault()
    $(addButton).show()
    $(removeButton).hide()
    $(removeIndicator).prop('checked', true)
    uppy.cancelAll()
  })
}

export default fileUpload