document.addEventListener('turbolinks:load', function() {

  $('.js-load-more').each(function() {

    const $loadMore = $(this);
    let isLoading = false;

    const hasNextPage = $loadMore.data('hasNextPage');

    if (!hasNextPage) {
      $loadMore.closest('.js-more').hide();
    }

    $loadMore.on('click', function(e) {
      e.preventDefault();
      fetchSearchData();
    });

    function fetchSearchData() {
      if (isLoading) { return }
      SearchLoader.show();

      isLoading = true;
      const { page, url, pagename } = $loadMore.data();
      const newPage = parseInt(page, 10) + 1;
      const paramName = pagename ? pagename : 'page';
      const separator = url.includes('?') ? '&' : '?';

      $.ajax({
        url: `${url}${separator}${paramName}=${newPage}`,
        dataType: 'script'
      }).done(function() {
        $loadMore.data('page', newPage);
      }).always(function() {
        isLoading = false;
        SearchLoader.hide();
      });
    }

  });

});