const GalleryCarousel = window.GalleryCarousel = {
  settings() {
    return {
      dots: true,
      infinite: false,
      accessibility: true,
      fade: true,
      useTransform: true,
      prevArrow: '<i class="icon-40-arrow-left"></i>',
      nextArrow: '<i class="icon-40-arrow-right"></i>',
      customPaging: function(slick,index) {
          var targetImage = slick.$slides.eq(index).find('img').data('thumb');
          return '<img src=" ' + targetImage + ' "/>';
      },
    }
  },
  init(element) {
    $(element).each(function() {
      const $this = $(this);
      const options = $(this).data('options') || {};
      $this.slick(GalleryCarousel.settings(options));

      $(document).on('keydown', function(e) {
        if (e.keyCode === 37) {
          $this.slick('slickPrev');
        }
        if (e.keyCode === 39) {
          $this.slick('slickNext');
        }
      });
    });
  }
}

document.addEventListener('turbolinks:load', function() {
  GalleryCarousel.init('.js-gallery-carousel');

  $('#gallery-modal-video').on('hidden.bs.modal', function (e) {
    const $videoPlayer = $(this).find('.js-video-player');
    if ($videoPlayer) {
      $videoPlayer[0].pause();
    }
  });

});

$(document).on('turbolinks:before-cache', function() {
  $('.js-gallery-carousel').slick('unslick');
});