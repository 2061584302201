document.addEventListener('turbolinks:load', function() {
  var $architectsSearchInput = $('.js-architects__form input');
  var $architectsFilter = $('.js-architects__form .cta .form-group');
  var $clearSearch = $('.icon-close-big');
  var $clearInput = $('.icon-close');
  var $searchBtn = $('.js-search__btn');
  var $base_url = window.location.origin + window.location.pathname;

  function hideButtons() {
    $clearInput.addClass('hide');
    $searchBtn.addClass('hide');
  }

  function showButtons() {
    $clearInput.removeClass('hide');
    $searchBtn.removeClass('hide');
  }

  if ($(window).width() >= 768) {
    $architectsSearchInput.on('click', function(){
      $(this).addClass('expand-search');

      if ($(this).val() != "") {
        showButtons();
      }
    });

    // leave search field expanded if input isn't empty
    if ($architectsSearchInput.val() != "") {
      $architectsSearchInput.addClass('expand-search');
    }

    // remove expanded search on click outside of input
    $(document).on('click', function(e) {
      if (e.target.id != 'architects-search' && e.target.id != 'clear-search' && e.target.id != 'submit-form-btn') {
        $("#architects-search").removeClass('expand-search');
        hideButtons();
      }
    });
  }

  // prevent form submit on empty search field
  $('.js-architects__form').on('submit', function () {
    var input = $.trim($architectsSearchInput.val());

    if (input === '') {
      return false;
    }
  });

  // clear input field
  $clearInput.on('click', function() {
    $architectsSearchInput.val("");
    if ($architectsSearchInput.val() == "") {
      hideButtons();
    }
  });

  $clearSearch.on('click', function() {
    window.location.replace($base_url);
  });

  // hide/show search
  $architectsSearchInput.on('keyup', function(){
    if ($architectsSearchInput.val() === "") {
      hideButtons();
    }
    else {
      showButtons();
    }
  });

  // dropdown filter
  $architectsFilter.on('change', function() {
    var $selectedFilter = $('.select2-selection').text().toLowerCase();
    var $filteredUrl = $base_url + `?sort=${$selectedFilter}`;

    window.location.replace($filteredUrl);
  })
});